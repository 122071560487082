import React, { createContext, useMemo, useState } from 'react';
import leaflet from 'leaflet';
import { Geometry, Position } from 'geojson';

import { FieldWithMetaAndGeoData } from '../interfaces/Fields';
import { AnyARecord } from 'dns';

class VoidContext implements EditFieldContext {
  get edittedField(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setEdittedField(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get drawControls(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setDrawControls(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get drawControlsCircle(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setDrawControlsCircle(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showDrawControls(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowDrawControls(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get eventContext(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setEventContext(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get drawControlsCut(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setDrawControlsCut(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get polygonAreaToClip(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setPolygonAreaToClip(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showDottedPolygon(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowDottedPolygon(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get eventData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setEventData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get polygonAreaToEdit(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setPolygonAreaToEdit(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get clippedMultiPolygonData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setClippedMultiPolygonData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get measurePolygonArea(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setMeasurePolygonArea(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get perimeter(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setPerimeter(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get importFile(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setImportFile(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get importFileFormButtonDisabled(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setImportFileFormButtonDisabled(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get dataToCreateMultiplePolygons(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setDataToCreateMultiplePolygons(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get disabledDownload(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setDisabledDownload(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

export interface EditField {
  field: FieldWithMetaAndGeoData;
  type:
    | 'polygon'
    | 'data'
    | 'circle'
    | 'polygonCut'
    | 'multipolygonCut'
    | 'share';
}

export interface PolygonAreaToClip {
  layer: {
    _latLngs: [];
  };
  layerType: string;
}

export interface dataToCreateMultiplePolygonsProps {
  name: string;
  coordinates: Position[];
  producer: number;
  index: number;
  farm?: string;
  fiscal_name?: string;
  owner?: number;
}

interface EditFieldContext {
  edittedField: EditField | null;
  setEdittedField: (editField: EditField | null) => void;
  drawControlsCircle: leaflet.Draw.Circle | undefined;
  setDrawControlsCircle: (
    drawControls?: leaflet.Draw.Circle | undefined
  ) => void;
  drawControlsCut: any | undefined;
  setDrawControlsCut: (drawControlsCut?: any | undefined) => void;
  showDrawControls: boolean;
  setShowDrawControls: (state: boolean) => void;
  eventContext: null | leaflet.Polygon<any>;
  setEventContext: (event: null | leaflet.Polygon<any>) => void;
  polygonAreaToClip: any;
  setPolygonAreaToClip: (
    polygonAreaToClip: null | leaflet.DrawEvents.Created
  ) => void;
  polygonAreaToEdit: null | leaflet.Polygon<any>;
  setPolygonAreaToEdit: (
    polygonAreaToEdit: null | leaflet.Polygon<any>
  ) => void;
  showDottedPolygon: undefined | leaflet.Polygon<any>;
  setShowDottedPolygon: (
    showDottedPolygon: undefined | leaflet.Polygon<any>
  ) => void;
  eventData: null | leaflet.DrawEvents.Created;
  setEventData: (event: null | leaflet.DrawEvents.Created) => void;
  clippedMultiPolygonData: null | leaflet.Polygon;
  setClippedMultiPolygonData: (
    clippedPolygonData: null | leaflet.Polygon
  ) => void;
  measurePolygonArea: any;
  setMeasurePolygonArea: Function;
  perimeter: number;
  setPerimeter: Function;
  importFile: boolean;
  setImportFile: Function;
  importFileFormButtonDisabled: boolean;
  setImportFileFormButtonDisabled: Function;
  dataToCreateMultiplePolygons: dataToCreateMultiplePolygonsProps[] | undefined;
  setDataToCreateMultiplePolygons: Function;
  disabledDownload: boolean;
  setDisabledDownload: Function;
}

export const EditFieldContext = createContext<EditFieldContext>(
  new VoidContext()
);

export const EditFieldProvider: React.FC = ({ children }) => {
  const [edittedField, setEdittedField] = useState<EditField | null>(null);
  const [drawControlsCircle, setDrawControlsCircle] = useState<
    leaflet.Draw.Circle | undefined
  >();
  const [drawControlsCut, setDrawControlsCut] = useState<any | undefined>();
  const [showDrawControls, setShowDrawControls] = useState(false);
  const [eventData, setEventData] = useState<null | leaflet.DrawEvents.Created>(
    null
  );
  const [eventContext, setEventContext] = useState<null | leaflet.Polygon<any>>(
    null
  );
  const [
    polygonAreaToClip,
    setPolygonAreaToClip,
  ] = useState<null | leaflet.DrawEvents.Created>(null);
  const [
    polygonAreaToEdit,
    setPolygonAreaToEdit,
  ] = useState<null | leaflet.Polygon<any>>(null);
  const [showDottedPolygon, setShowDottedPolygon] = useState<
    undefined | leaflet.Polygon<any>
  >(undefined);
  const [
    clippedMultiPolygonData,
    setClippedMultiPolygonData,
  ] = useState<null | leaflet.Polygon>(null);
  const [measurePolygonArea, setMeasurePolygonArea] = useState<any>();
  const [perimeter, setPerimeter] = useState<number>(0);
  const [importFile, setImportFile] = useState<boolean>(false); //para mostras u ocultar formulario
  const [
    importFileFormButtonDisabled,
    setImportFileFormButtonDisabled,
  ] = useState<boolean>(false);
  const [
    dataToCreateMultiplePolygons,
    setDataToCreateMultiplePolygons,
  ] = useState<dataToCreateMultiplePolygonsProps[] | undefined>();
  const [disabledDownload, setDisabledDownload] = useState<boolean>(true);

  return (
    <EditFieldContext.Provider
      value={{
        edittedField,
        setEdittedField,
        drawControlsCircle,
        setDrawControlsCircle,
        showDrawControls,
        setShowDrawControls,
        eventContext,
        setEventContext,
        drawControlsCut,
        setDrawControlsCut,
        polygonAreaToClip,
        setPolygonAreaToClip,
        showDottedPolygon,
        setShowDottedPolygon,
        eventData,
        setEventData,
        setPolygonAreaToEdit,
        polygonAreaToEdit,
        clippedMultiPolygonData,
        setClippedMultiPolygonData,
        measurePolygonArea,
        setMeasurePolygonArea,
        perimeter,
        setPerimeter,
        importFile,
        setImportFile,
        importFileFormButtonDisabled,
        setImportFileFormButtonDisabled,
        dataToCreateMultiplePolygons,
        setDataToCreateMultiplePolygons,
        disabledDownload,
        setDisabledDownload,
      }}
    >
      {children}
    </EditFieldContext.Provider>
  );
};
