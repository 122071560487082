import React, { createContext, useMemo, useState } from 'react';
import { FieldWithMetaAndGeoData } from '../interfaces/Fields';
import { AllProducers } from '../hooks/useGetAllProducers';

class VoidContext implements VariablesContext {
  get measurementToolActivated(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setMeasurementToolActivated(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get editProducer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setEditProducer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get generateGraphicImg(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setGenerateGraphicImg(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get graphicImgAnnualPlanning(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setGraphicImgAnnualPlanning(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get imgWidthAndHeight(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setImgWidthAndHeight(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get checkTreatment(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setCheckTreatment(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get brechaName(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setBrechaName(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface VariablesContext {
  measurementToolActivated: boolean;
  setMeasurementToolActivated: Function;
  editProducer: AllProducers | undefined;
  setEditProducer: Function;
  generateGraphicImg: string | undefined;
  setGenerateGraphicImg: Function;
  graphicImgAnnualPlanning: string | undefined;
  setGraphicImgAnnualPlanning: Function;
  imgWidthAndHeight: { width: number; height: number } | undefined;
  setImgWidthAndHeight: Function;
  checkTreatment: boolean;
  setCheckTreatment: Function;
  brechaName: FieldsValues | undefined;
  setBrechaName: Function;
}

export const VariablesContext = createContext<VariablesContext>(
  new VoidContext()
);

interface FieldsValues {
  value: number;
  label: string;
  key: number;
}

export const VariablesProvider: React.FC = ({ children }) => {
  const [measurementToolActivated, setMeasurementToolActivated] = useState(
    false
  );
  const [generateGraphicImg, setGenerateGraphicImg] = useState<string>();
  const [editProducer, setEditProducer] = useState<AllProducers>();
  const [checkTreatment, setCheckTreatment] = useState<boolean>(false);
  const [brechaName, setBrechaName] = useState<FieldsValues>();
  const [graphicImgAnnualPlanning, setGraphicImgAnnualPlanning] = useState<
    string | undefined
  >();
  const [imgWidthAndHeight, setImgWidthAndHeight] = useState<{
    width: number;
    height: number;
  }>();

  return (
    <VariablesContext.Provider
      value={{
        measurementToolActivated,
        setMeasurementToolActivated,
        editProducer,
        setEditProducer,
        generateGraphicImg,
        setGenerateGraphicImg,
        graphicImgAnnualPlanning,
        setGraphicImgAnnualPlanning,
        imgWidthAndHeight,
        setImgWidthAndHeight,
        checkTreatment,
        setCheckTreatment,
        brechaName,
        setBrechaName,
      }}
    >
      {children}
    </VariablesContext.Provider>
  );
};
