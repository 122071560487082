import React, { createContext, useState, useContext, useEffect } from 'react';
import { UserContext } from '../UserContext';
import { pioneerStyles } from './PioneerStyles';
import { cortevaStyles } from './CortevaStyles';
import { brevantStyles } from './BrevantStyles';

class VoidContext implements StylesAcordingToUserContext {
  get loadTheStyles(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setIndexBrand(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get indexBrand(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

export interface StylesAcordingToUserContext {
  loadTheStyles: {
    brand: string;
    navbar: {
      background: string;
    };
    buttonGlobal: {
      stuffedButton: string;
      stuffedButtonHover: string;
      goshtButton: string;
      radioButtonHover: string;
      measureOptions: string;
      measureOptionsHover: string;
    };
    genericIcons: {
      iconEdit: string;
      iconDelete: string;
    };
  };
  setIndexBrand: Function;
  indexBrand: string;
}

export const StylesAcordingToUserContext = createContext<
  StylesAcordingToUserContext
>(new VoidContext());

export const StylesAcordingToUserProvider: React.FC = ({ children }) => {
  const {
    user: { data },
  } = useContext(UserContext);

  const [indexBrand, setIndexBrand] = useState<string>(() => {
    if (window.location.href.includes('pioneer')) {
      return 'pioneer';
    } else {
      return 'corteva';
    }
  });

  const [loadTheStyles, setLoadTheStyles] = useState(cortevaStyles);

  useEffect(() => {
    switch (data && data.brand_id) {
      case 1:
        setLoadTheStyles(brevantStyles);
        break;
      case 2:
        setLoadTheStyles(pioneerStyles);
        break;
      case 7:
        setLoadTheStyles(cortevaStyles);
        break;
      default:
        setLoadTheStyles(cortevaStyles);
        break;
    }
  }, [data]);

  return (
    <StylesAcordingToUserContext.Provider
      value={{
        loadTheStyles,
        indexBrand,
        setIndexBrand,
      }}
    >
      {children}
    </StylesAcordingToUserContext.Provider>
  );
};
