import DeleteIcon from '../../assets/icons/icon-_iconos-genericos-borrar.svg';
import EditIcon from '../../assets/icons/icon-_iconos-genericos-editar.svg';

export const cortevacolors = {
  color_White: '#ffffff',
  color_black: '#000000',
  color_black_1: '#222222',
  color_black_2: '#2d2d2d',
  color_blue_0_opacity: 'rgba(133, 190, 250, 0.5)',
  color_blue_0: '#85befa',
  color_blue_1_Oopacity: 'rgba(73, 139, 208, 0.5)',
  color_blue_1: '#498bd0',
  color_blue_2_opacity: 'rgba(17, 90, 174, 0.5)',
  color_blue_2: '#285ba8',
  color_blue_3_opacity: 'rgba(30, 79, 135, 0.5)',
  color_blue_3: '#1e4f87',
  color_blue_4: '#7da5d0',
  color_blue_5: '#4190f7',
  color_blue_6: '#78b3ff',
  color_grey_1: '#faf7f7',
  color_grey_2: '#f0f1f3',
  color_grey_3: '#c1c1c1',
  color_grey_4: '#7f7f7f',
  color_grey_5: '#fafafa',
  color_grey_6: '#afafaf',
  color_grey_7: '#d1d1d1',
  color_yellow_1: '#e2ae06',
  color_yellow_2: '#e9cf79',
  color_green_1: '#8db146',
  color_green_2: '#9ac84e',
  color_danger_1: '#eb445a',
  color_antd_error: '#ff4d4f',
  border_radius: '5px',
  color_gray: '#000000a6',

  primary: 'primary',
  ghost: 'ghost',
};

export const cortevaStyles = {
  brand: 'corteva',
  navbar: {
    background: cortevacolors.color_blue_2,
  },
  buttonGlobal: {
    stuffedButton: cortevacolors.color_blue_5,
    stuffedButtonHover: cortevacolors.color_blue_5,
    goshtButton: cortevacolors.color_blue_5,
    radioButtonHover: cortevacolors.color_blue_6,
    measureOptions: cortevacolors.color_gray,
    measureOptionsHover: cortevacolors.color_blue_5,
  },
  genericIcons: {
    iconDelete: DeleteIcon,
    iconEdit: EditIcon,
  },
};
