export const API_URLs: { [key: string]: string } = {
  local: 'http://localhost:8000',
  dev: 'https://dev.api.milote.dev-corteva-arg.com',
  prod: 'https://api.milote.io',
};

export const ONESOIL_URLs: { [key: string]: string } = {
  local: 'http://yield-feature-milote-integration.dev-fe.onesoil.ai',
  dev: 'http://yield-feature-milote-integration.dev-fe.onesoil.ai',
  prod: 'https://milote.onesoil.ai',
};
