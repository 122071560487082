import React, { createContext, useCallback, useState } from 'react';
import { DrawMap } from 'leaflet';

import { LayerImage } from '../hooks/useGetLayerImages';
import { ResponseLabels } from '../hooks/useLabels';

class VoidContext implements MapContext {
  get mapClickEvent(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setMapClick(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get captureMapEvent(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setCaptureMapEvent(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get imageLayer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setImageLayer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get soilLayer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setSoilLayer(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get loadingImages(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setLoadingImages(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setLeafletElement(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get leafletElement(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showPublicNotes(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowPublicNotes(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showFieldNotes(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowFieldNotes(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showAddNoteModal(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowAddNoteModal(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowAlert(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showAlert(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setTitle(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get title(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get showMenu(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setShowMenu(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setAoiNull(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get aoiNull(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setAlertMsg(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get alertMsg(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get labels(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get getLabels(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get leafletElementPm(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setLeafletElementPm(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface MapEvent {
  event: L.LeafletMouseEvent | undefined;
  fieldId: number | undefined;
}
interface MapContext {
  setLeafletElement: (leafletElement: DrawMap | undefined) => void;
  leafletElement: DrawMap | undefined;
  leafletElementPm: any;
  setLeafletElementPm: (leafletElementPm: any) => void;
  mapClickEvent: MapEvent;
  /** Sets the map click event, if not event is provided then sets the 'null' state.
   */
  setMapClick: (event?: MapEvent) => void;
  captureMapEvent: boolean;
  /** Set the allow's state for listening or not the click event in the map. */
  setCaptureMapEvent: React.Dispatch<React.SetStateAction<boolean>>;
  imageLayer: LayerImage[] | undefined;
  setImageLayer: (layerImage: LayerImage[] | undefined) => void;
  loadingImages: boolean;
  setLoadingImages: (loading: boolean) => void;
  soilLayer: 'soil' | 'clay' | 'sand' | 'silt' | undefined;
  setSoilLayer: (
    soilLayer: 'soil' | 'clay' | 'sand' | 'silt' | undefined
  ) => void;
  showPublicNotes: boolean;
  setShowPublicNotes: (state: boolean) => void;
  showFieldNotes: boolean;
  setShowFieldNotes: (state: boolean) => void;
  showAddNoteModal: boolean;
  setShowAddNoteModal: (state: boolean) => void;
  showAlert: boolean;
  setShowAlert: (state: boolean) => void;
  title: string;
  setTitle: (state: string) => void;
  showMenu: boolean;
  setShowMenu: (state: boolean) => void;
  aoiNull: boolean;
  setAoiNull: (state: boolean) => void;
  alertMsg: string;
  setAlertMsg: (state: string) => void;
  labels: ResponseLabels[] | undefined;
  getLabels: (layerImage: ResponseLabels[] | undefined) => void;
}

export const MapContext = createContext<MapContext>(new VoidContext());

export const MapProvider: React.FC = ({ children }) => {
  const [labels, getLabels] = useState<ResponseLabels[] | undefined>();
  const [mapClickEvent, setMapClickEvent] = useState<MapEvent>({
    event: undefined,
    fieldId: undefined,
  });
  const [soilLayer, setSoilLayer] = useState<
    'soil' | 'clay' | 'sand' | 'silt' | undefined
  >();
  const [leafletElement, setLeafletElement] = useState<DrawMap | undefined>();
  const [leafletElementPm, setLeafletElementPm] = useState<any>();
  const [imageLayer, setImageLayer] = useState<LayerImage[] | undefined>();
  const [loadingImages, setLoadingImages] = useState(false);
  const [captureMapEvent, setCaptureMapEvent] = useState(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showFieldNotes, setShowFieldNotes] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [aoiNull, setAoiNull] = useState(false);
  // const [
  //   showPublicNotesStorage,
  //   setShowPublicNotesStorage,
  // ] = useLocalStorageState<boolean>('showPublicNotes', false);

  const [showPublicNotes, setShowPublicNotes] = useState<boolean>(false);
  const [title, setTitle] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string>('');
  // const setShowPublicNotes = useCallback(
  //   (value: boolean) => {
  //     setShowPublicNotesStorage(value);
  //     setShowPublicNotesState(value);
  //   },
  //   [setShowPublicNotesStorage]
  // );

  const setMapClick = useCallback(
    (event?: MapEvent) => {
      if (captureMapEvent) {
        event
          ? setMapClickEvent(event)
          : setMapClickEvent({ event: undefined, fieldId: undefined });
      }
    },
    [captureMapEvent, setMapClickEvent]
  );

  return (
    <MapContext.Provider
      value={{
        leafletElement,
        setLeafletElement,
        soilLayer,
        setSoilLayer,
        mapClickEvent,
        setMapClick,
        captureMapEvent,
        setCaptureMapEvent,
        imageLayer,
        setImageLayer,
        loadingImages,
        setLoadingImages,
        showPublicNotes,
        setShowPublicNotes,
        showFieldNotes,
        setShowFieldNotes,
        showAddNoteModal,
        setShowAddNoteModal,
        showAlert,
        setShowAlert,
        title,
        setTitle,
        showMenu,
        setShowMenu,
        aoiNull,
        setAoiNull,
        alertMsg,
        setAlertMsg,
        labels,
        getLabels,
        leafletElementPm,
        setLeafletElementPm,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
