import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages } from 'react-intl.macro';
import { useIntl } from 'react-intl';
import { notification } from 'antd';

import { SignUpForm, User } from '../interfaces/User';
import { axiosLocal, axiosMiloteApiInstance } from '../axios';
import {
  API_END_POINT_LOG_IN,
  API_END_POINT_GET_USER,
  API_END_POINT_SIGN_UP,
  API_END_POINT_RECOVER_PASSWORD,
  API_END_POINT_ACTIVATE_ACCOUNT,
  API_END_POINT_USER_DATA_UPDATE,
} from '../axios/apiEndPoints';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import { AsyncData } from '../interfaces/AsyncData';
import axios from 'axios';
import { LenguageContext } from './LenguagesContext';

export const scope = 'messages.user-context';

const messages = defineMessages({
  notificationType: {
    id: `${scope}.notificationType`,
    defaultMessage: 'Usuario',
  },
  recoverPasswordError: {
    id: `${scope}.recoverPasswordError`,
    defaultMessage:
      'El mail ingresado no corresponde a un usuario válido o algo salió mal.',
  },
  recoverPasswordSuccess: {
    id: `${scope}.recoverPasswordSuccess`,
    defaultMessage: 'Enviamos un email a tu cuenta',
  },
  changeRecoverPasswordError: {
    id: `${scope}.changeRecoverPasswordError`,
    defaultMessage:
      'No pudimos cambiar tu contraseña, intentalo de nuevo mas tarde.',
  },
  changeRecoverPasswordSuccess: {
    id: `${scope}.changeRecoverPasswordSuccess`,
    defaultMessage: 'Tu contraseña fue cambiado con éxito',
  },
  activateAccountError: {
    id: `${scope}.activateAccountError`,
    defaultMessage: 'No pudimos activar tu cuenta con éxito.',
  },
  activateAccountSuccess: {
    id: `${scope}.activateAccountSuccess`,
    defaultMessage: 'Activamos tu cuenta con éxito',
  },
  loginError: {
    id: `${scope}.loginError`,
    defaultMessage: 'Email o contraseña incorrectos',
  },
});

class VoidContext implements UserContext {
  get user(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get login(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get logout(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get signUp(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get signUpLoading(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get recoverPassword(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get changeRecoverPassword(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get activateAccount(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setUserData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get countryCode(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get token(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setUserFromToken(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get key(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get changeUserData(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

type LoginAction = (
  username: string,
  password: string,
  redirectPath?: string
) => Promise<void>;
type LogoutAction = () => void;
type SignUpAction = (value: SignUpForm) => void;
type RecoverPasswordAction = (email: string) => void;
type ActivateAccountAction = (activateTokem: string, userToken: string) => void;
type ChangeRecoverPasswordAction = (
  email: string,
  token: string
) => Promise<void>;

interface UserContext {
  activateAccount: ActivateAccountAction;
  setUserFromToken: Function;
  user: AsyncData<User, UserLoading>;
  login: LoginAction;
  logout: LogoutAction;
  signUp: SignUpAction;
  recoverPassword: RecoverPasswordAction;
  changeRecoverPassword: ChangeRecoverPasswordAction;
  setUserData: (User: User | null) => void;
  countryCode: string;
  token: string | undefined;
  key: string;
  changeUserData: Function;
}
interface UserLoading {
  isRecoveringPassword: boolean;
  isLoginLoading: boolean;
  isSetUserLoading: boolean;
  isSignUpLoading: boolean;
}

const useUserLogin = (): UserContext => {
  const [token, setToken] = useLocalStorageState<string | undefined>(
    'milote-token',
    undefined
  );
  const [, setShowModal] = useLocalStorageState<boolean | undefined>(
    'SHOW_TRIAL_MODAL',
    undefined
  );
  const intl = useIntl();
  const { language, setLanguage } = useContext(LenguageContext);

  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isSetUserLoading, setIsSetUserLoading] = useState<boolean>(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState<boolean>(false);
  const [isRecoveringPassword, setIsRecoveringPassword] = useState<boolean>(
    false
  );

  const [countryCode, setCountryCode] = useState('');
  const [error, setError] = useState<any>(null);
  const [userData, setUserData] = useState<User | null>(null);
  const key = 'JWT';

  const languageString: { [key: number]: string } = {
    1: 'es',
    2: 'en',
  };

  const history = useHistory();

  const redirectPathRef = useRef<string>(
    `${history.location.pathname}${history.location.search}`
  );

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_calling_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axiosLocal.defaults.headers.common[
      'Authorization'
    ] = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiODgyZWQ3YTMtODRhNy00NDljLThiMTItZGIzNDBiZGZlZTc5IiwibmFtZSI6Im1pbG90ZSIsImlhdCI6MTY2NDQ0MDE0MH0.dLXhjG44YH_pUQnQQZ2Qq5sxiKN52EWj4lbIvKj6luk`;
  }, []);

  useEffect(() => {
    getGeoInfo();
  }, []);

  const activateAccount = async (
    activateTokem: string,
    userToken: string
  ): Promise<void> => {
    try {
      await axiosMiloteApiInstance.get(
        `${API_END_POINT_ACTIVATE_ACCOUNT}/${activateTokem}/${userToken}`
      );

      history.push('/auth/log-in');
      notification.success({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.activateAccountSuccess),
        placement: 'topRight',
      });
    } catch (error) {
      notification.error({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.activateAccountError),
        placement: 'topRight',
      });

      console.error(error);
    }
  };

  const changeRecoverPassword = async (
    password: string,
    token: string
  ): Promise<void> => {
    setIsRecoveringPassword(true);

    try {
      await axiosMiloteApiInstance.post(
        `${API_END_POINT_RECOVER_PASSWORD}confirm/`,
        {
          password,
          token,
        }
      );

      notification.success({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.changeRecoverPasswordSuccess),
        placement: 'topRight',
      });
    } catch (error) {
      notification.error({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.changeRecoverPasswordError),
        placement: 'topRight',
      });
      console.error(error);
      setIsRecoveringPassword(false);
      throw error;
    }

    setIsRecoveringPassword(false);
  };

  const recoverPassword = async (email: string): Promise<void> => {
    setIsRecoveringPassword(true);

    try {
      await axiosMiloteApiInstance.post(API_END_POINT_RECOVER_PASSWORD, {
        email,
      });
      notification.success({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.recoverPasswordSuccess),
        placement: 'topRight',
      });
    } catch (error) {
      notification.error({
        message: intl.formatMessage(messages.notificationType),
        description: intl.formatMessage(messages.recoverPasswordError),
        placement: 'topRight',
      });

      console.error(error);
    }
    setIsRecoveringPassword(false);
  };

  const setTokenFromLoginCredentials = async (
    username: string,
    password: string,
    redirectPath: string = '/'
  ): Promise<void> => {
    setIsLoginLoading(true);
    redirectPathRef.current = redirectPath;
    const provider: string = 'milote';

    try {
      setError(undefined);
      const {
        data: { token: new_token },
      } = await axiosMiloteApiInstance.post(API_END_POINT_LOG_IN, {
        username,
        password,
        provider,
      });

      setUserData(null);
      setToken(new_token);
    } catch (error) {
      console.error(error);

      setToken(undefined);

      setError(intl.formatMessage(messages.loginError));
    }

    setIsLoginLoading(false);
  };

  const changeUserData = async (newData: {
    selected_language?: string;
    selected_season?: string;
  }) => {
    try {
      const { data: user } = await axiosMiloteApiInstance.patch(
        API_END_POINT_USER_DATA_UPDATE,
        newData
      );
    } catch (error) {
      console.log(error);
    }
  };

  const setUserFromToken = useCallback(
    async (token: string): Promise<void> => {
      setIsSetUserLoading(true);

      try {
        const { data: user } = await axiosMiloteApiInstance.get(
          API_END_POINT_GET_USER,
          {
            headers: {
              Authorization: `${key} ${token}`,
            },
          }
        );

        axiosMiloteApiInstance.defaults.headers.common[
          'Authorization'
        ] = `${key}  ${token}`;
        let userHarcod: User = {
          accepted_terms: user.accepted_terms,
          country: user.country,
          created: user.created,
          facebook_email: user.facebook_email,
          facebook_id: user.facebook_id,
          firstname: user.firstname,
          fiscal_id: user.fiscal_id,
          google_email: user.google_email,
          google_id: user.google_id,
          id: user.id,
          is_over_18: user.is_over_18,
          lastname: user.lastname,
          user: user.user,
          global_tutorial: user.global_tutorial,
          field_tutorial: user.field_tutorial,
          countryOfUser_id: user.countryOfUser_id,
          brand_id: user.brand_id,
          has_onesoil_access: user.has_onesoil_access,
          phone_number: user.phone_number,
          profileLogo: user.profilelogo,
          business_name: user.business_name,
          user_id: user.user_id,
          selected_language: user.selected_language,
          selected_season: user.selected_season,
          show_sensitive_information: user.show_sensitive_information,
          is_field_agronomist: user.is_field_agronomist
            ? user.is_field_agronomist
            : undefined,
        };
        let newState = userHarcod.brand_id == 2 ? 'pioneer' : 'corteva';
        window.localStorage.setItem('Brand', JSON.stringify(newState));
        setLanguage({
          language: languageString[user.selected_language],
          id: user.selected_language,
        });

        setUserData(userHarcod);
      } catch (error) {
        setToken(undefined);
        console.error(error);
      }

      setIsSetUserLoading(false);
    },
    [setToken]
  );

  const logout = async (): Promise<void> => {
    setUserData(null);
    setShowModal(undefined);
    setToken(undefined);
  };

  const signUp = async (signUpForm: SignUpForm) => {
    setIsSignUpLoading(true);
    const { username, password, ...form } = signUpForm;
    const send = { user: { email: username, password }, ...form };
    try {
      await axiosMiloteApiInstance.post(API_END_POINT_SIGN_UP, send);
      setIsSignUpLoading(false);
    } catch (e) {
      setIsSignUpLoading(false);
      throw e;
    }
  };

  useEffect(() => {
    if (token !== undefined) {
      setUserFromToken(token);
    } else {
      setIsSetUserLoading(false);
    }
  }, [setUserFromToken, token]);

  const userId = userData?.id;

  useEffect(() => {
    if (userId) {
      if (redirectPathRef.current != '/home') {
        history.push(redirectPathRef.current);
      }
    }
  }, [userId, history]);

  return {
    setUserData,
    activateAccount,
    changeRecoverPassword,
    recoverPassword,
    setUserFromToken,
    user: {
      data: userData,
      loading: {
        isRecoveringPassword,
        isLoginLoading,
        isSetUserLoading,
        isSignUpLoading,
      },
      error,
    },
    login: setTokenFromLoginCredentials,
    logout,
    signUp,
    countryCode,
    token,
    key,
    changeUserData,
  };
};

export const UserContext = createContext<UserContext>(new VoidContext());

export const UserContextProvider: React.FC = ({ children }) => {
  const userContext = useUserLogin();

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
