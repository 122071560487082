import React, { useState, useEffect } from 'react';
import axios from 'axios';

import packageJson from '../../package.json';

const withClearCache = (Component: React.FC) => (props: any) => {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  useEffect(() => {
    axios
      .get('/meta.json')
      .then((response) => {
        const meta = response.data;
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        if (currentVersionDate < latestVersionDate) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLatestBuildDate(true);
      });
  }, []);

  return <>{isLatestBuildDate ? <Component {...props} /> : null}</>;
};

export default withClearCache;
