import axios from 'axios';
import { API_URLs, ONESOIL_URLs } from './constants';

export const axiosMiloteApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENV
    ? API_URLs[process.env.REACT_APP_API_ENV]
    : API_URLs.dev,
});

export const onesoilUrl = process.env.REACT_APP_API_ENV
  ? ONESOIL_URLs[process.env.REACT_APP_API_ENV]
  : ONESOIL_URLs.dev;

export const axiosRainviewerApiInstance = axios.create({
  baseURL: 'https://api.rainviewer.com',
});

export const axiosRealearthApiInstance = axios.create({
  baseURL: 'https://realearth.ssec.wisc.edu/api',
});

export const axiosGoogleFindPlacesApiInstance = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode/json',
});

export const localFede = axios.create({
  baseURL: 'http://192.168.100.3:8000',
});

export const axiosLocal = axios.create({
  baseURL: process.env.REACT_APP_API_ENV
    ? API_URLs[process.env.REACT_APP_API_ENV]
    : API_URLs.dev,
});
