import { Activities } from '../hooks/useGetActivities';

export enum PeriodType {
  'WINTER' = '1',
  'SUMMER' = '2',
}

export interface GeoJSON {
  bbox: number[];
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
  features: Array<{
    area: number;
    type: string;
    properties: { id: string | null };
    geometry: {
      type: string;
      coordinates: Array<[number, number][]>;
    };
  }>;
  name: string;
  type: string;
}

export interface Season {
  created: string;
  finish_date: string;
  id: number;
  initial_date: string;
  name: string;
}

export enum FieldPaymentStatus {
  PAYMENT_STATUS_UNPAID = 0,
  PAYMENT_STATUS_PENDDING,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_TRIAL,
}

export interface FieldWithMetaAndGeoData {
  farm: string;
  field_owner: number;
  fieldgeodata_set: Array<{
    country: string;
    county: string;
    created: string;
    field_meta_data: number;
    geojson: GeoJSON;
    has?: number;
    id: number;
    route: string;
    state: string;
    wkt_polygon: string;
    aoi_id: number;
  }>;
  field_producer: FieldProducer;
  fieldstatusbyproduction_set: Array<FieldStatusByProduction>;
  fiscal_name: string;
  has_to_be_sent_to_onesoil: boolean;
  id: number;
  name: string;
  owner: number;
  is_treatment_project: boolean;
  treatment_id: number;
  payment_status: FieldPaymentStatus;
  user: number;
}

export interface FieldProducer {
  email: string;
  id: number;
  location: string;
  name: string;
  user: number;
}

export interface FieldStatusByProduction {
  activity_set: Activities[];
  created: string;
  crop: number | undefined;
  field_meta_data: number;
  harvest_date: string;
  pioneer_overseeding_coverage_status: number;
  id: number;
  season: Season | null;
  sowing_date: string;
  production_type: PeriodType;
}
