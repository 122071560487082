import React, { createContext, useState } from 'react';

class VoidContext implements TutorialContext {
  get globalTutorial(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get fieldTutorial(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setGlobalTutorial(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setFieldTutorial(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface TutorialContext {
  globalTutorial: boolean;
  fieldTutorial: boolean;
  setGlobalTutorial: (value: boolean) => void;
  setFieldTutorial: (value: boolean) => void;
}

export const TutorialContext = createContext<TutorialContext>(
  new VoidContext()
);

export const TutorialProvider: React.FC = ({ children }) => {
  const [globalTutorial, setGlobalTutorial] = useState<boolean>(false);
  const [fieldTutorial, setFieldTutorial] = useState<boolean>(false);

  return (
    <TutorialContext.Provider
      value={{
        globalTutorial,
        setGlobalTutorial,
        fieldTutorial,
        setFieldTutorial,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
