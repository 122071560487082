import DeleteIconPioneer from '../../assets/icons/iconsPioneer/icon-_iconos-genericos-borrar.svg';
import EditIconPioneer from '../../assets/icons/iconsPioneer/icon-_iconos-genericos-editar.svg';

const pioneerColors = {
  color_White: '#ffffff',
  colorGreen_1: '#3b7b2b',
  colorGreen_2: '#689435',
  colorGreen_3: '#7cb140',
  color_orange_1: '#ef6023',
  color_orange_2: '#ef7f23',
  color_gray: '#000000a6',
};

export const pioneerStyles = {
  brand: 'pioneer',
  navbar: {
    background: pioneerColors.colorGreen_1,
  },
  buttonGlobal: {
    stuffedButton: pioneerColors.color_orange_1,
    stuffedButtonHover: pioneerColors.color_orange_2,
    goshtButton: pioneerColors.colorGreen_2,
    radioButtonHover: pioneerColors.colorGreen_2,
    measureOptions: pioneerColors.color_gray,
    measureOptionsHover: pioneerColors.color_orange_2,
  },
  genericIcons: {
    iconDelete: DeleteIconPioneer,
    iconEdit: EditIconPioneer,
  },
};
