import DeleteIconBrevant from '../../assets/icons/iconsBrevant/icon-_iconos-genericos-borrar.svg';
import EditIconBrevant from '../../assets/icons/iconsBrevant/icon-_iconos-genericos-editar.svg';

const brevantColors = {
  color_White: '#ffffff',
  colorBordo_1: '#750d2b',
  colorBordo_2: '#690c27',
  colorBordo_3: '#8f1b42',
  color_orange_1: '#ef6023',
  color_orange_2: '#ef7f23',
  color_white: '#ffffff',
  color_gray: '#000000a6;',
};

export const brevantStyles = {
  brand: 'brevant',
  navbar: {
    background: brevantColors.colorBordo_1,
  },
  buttonGlobal: {
    stuffedButton: brevantColors.color_orange_1,
    stuffedButtonHover: brevantColors.color_orange_2,
    goshtButton: brevantColors.colorBordo_2,
    radioButtonHover: brevantColors.colorBordo_2,
    measureOptions: brevantColors.color_gray,
    measureOptionsHover: brevantColors.color_orange_2,
  },
  genericIcons: {
    iconDelete: DeleteIconBrevant,
    iconEdit: EditIconBrevant,
  },
};
