export function objectFilter<A>(
  object: Record<any, A>,
  fn: (value: A) => boolean
) {
  return Object.entries(object).reduce((current, [key, value]: [any, A]) => {
    if (fn(value)) {
      return {
        ...current,
        [key]: value,
      };
    } else {
      return current;
    }
  }, {});
}
