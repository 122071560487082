import React, { createContext, useState } from 'react';

class VoidContext implements NotificationContext {
  get notification(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get getNotification(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get notificationRead(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setNotificationRead(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface NotificationContext {
  notification: boolean;
  getNotification: (value: boolean) => void;
  notificationRead: boolean;
  setNotificationRead: (value: boolean) => void;
}

export const NotificationContext = createContext<NotificationContext>(
  new VoidContext()
);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notificationRead, setNotificationRead] = useState<boolean>(false);
  const [notification, getNotification] = useState<boolean>(false);

  return (
    <NotificationContext.Provider
      value={{
        getNotification,
        notification,
        setNotificationRead,
        notificationRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
