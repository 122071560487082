import React, { createContext, useState } from 'react';

import { PeriodType } from '../interfaces/Fields';

class VoidContext implements SeasonAndPeriodContext {
  get season(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get changeSeason(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get period(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get changePeriod(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface SeasonAndPeriodContext {
  season: number | undefined;
  changeSeason: (season: number | undefined) => void;
  period: PeriodType | undefined;
  changePeriod: (season: PeriodType | undefined) => void;
}

const useSeasonAndPeriod = (): SeasonAndPeriodContext => {
  const [season, changeSeason] = useState<number | undefined>();
  const [period, changePeriod] = useState<PeriodType | undefined>(
    PeriodType.SUMMER
  );

  return {
    season,
    changeSeason,
    period,
    changePeriod,
  };
};

export const SeasonAndPeriodContext = createContext<SeasonAndPeriodContext>(
  new VoidContext()
);

export const SeasonAndPeriodContextProvider: React.FC = ({ children }) => {
  const seasonAndPeriodContext = useSeasonAndPeriod();

  return (
    <SeasonAndPeriodContext.Provider value={seasonAndPeriodContext}>
      {children}
    </SeasonAndPeriodContext.Provider>
  );
};
